import {format, distanceInWords, differenceInDays} from 'date-fns'
import React from 'react'
import {buildImageObj, cn} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PortableText from './portableText'
import Container from './container'
import styles from './product.module.css'
import {responsiveTitle3} from './typography.module.css'
import {Link} from 'gatsby'

function Product (props) {
  const {
    _rawBody,
    title,
    mainImage,
    publishedAt,
    gueltigAb,
    gueltigBis,
    price,
    reducedPrice
  } = props
  return (
    <article className={styles.root}>
      {mainImage && mainImage.asset && (
        <div className={styles.mainImage}>
          <img
            src={imageUrlFor(buildImageObj(mainImage))
              .width(400)
              .height(Math.floor((9 / 16) * 400))
              .fit('crop')
              .auto('format')
              .url()}
            alt={mainImage.alt}
          />
        </div>
      )}
      {differenceInDays(new Date(publishedAt), new Date()) <= 0 &&
        differenceInDays(new Date(gueltigAb), new Date()) <= 0 &&
        differenceInDays(new Date(gueltigBis), new Date()) >= 0 && (
        <Container>
          <div className={styles.grid}>
            <div className={styles.mainContent}>
              <h1 className={styles.title}>{title}</h1>
              {_rawBody && <PortableText blocks={_rawBody} />}
            </div>
            <aside className={styles.metaContent}>
              <div className={styles.text}>
                <h3 className={cn(responsiveTitle3, styles.title)}>{props.title}</h3>
                <span className={styles.reducedPrice}>CHF {props.reducedPrice}</span> statt{' '}
                <span className={styles.price}>CHF {props.price}</span>
                <br />
                Aktion g&uuml;ltig vom{' '}
                <span className={styles.gueltigAb}>
                  {format(props.gueltigAb, 'DD.MM.YYYY')}
                </span>{' '}
                bis zum{' '}
                <span className={styles.gueltigBis}>
                  {format(props.gueltigBis, 'DD.MM.YYYY')}
                </span>
              </div>
            </aside>
          </div>
        </Container>
      )}
    </article>
  )
}

export default Product
