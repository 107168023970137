import React from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import Product from '../components/product'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import {toPlainText} from '../lib/helpers'
import {format} from 'date-fns'

export const query = graphql`
  query ProductTemplateQuery($id: String!) {
    product: sanityProduct(id: {eq: $id}) {
      id
      title
      publishedAt
      isAktion
      gueltigAb
      gueltigBis
      price
      reducedPrice
      mainImage {
        ...SanityImage
        alt
      }
      title
      slug {
        current
      }

      _rawBody(resolveReferences: {maxDepth: 5})
    }
  }
`

const ProductTemplate = props => {
  const {data, errors} = props
  const product = data && data.product
  return (
    <Layout>
      {errors && <SEO title='GraphQL Error' />}
      {product && product.isAktion && (
        <SEO
          title={product.title || 'Untitled'}
          description={`Aktion: ${product.title} vom ${format(
            product.gueltigAb,
            'DD.MM.YYYY'
          )} bis ${format(product.gueltigBis, 'DD.MM.YYYY')} zum Aktionspreis von CHF ${
            product.reducedPrice
          }`}
          image={product.mainImage}
        />
      )}
      {product && !product.isAktion && (
        <SEO
          title={product.title || 'Untitled'}
          description={`Neues Produkt: ${product.title} ab ${format(
            product.gueltigAb,
            'DD.MM.YYYY'
          )} zum Preis von CHF ${product.reducedPrice}`}
          image={product.mainImage}
        />
      )}

      {product && (
        <SEO
          title={product.title || 'Untitled'}
          description={toPlainText(product.body)}
          image={product.mainImage}
        />
      )}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {product && <Product {...product} />}
    </Layout>
  )
}

export default ProductTemplate
